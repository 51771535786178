<template>
  <b-form-row>
    <template v-if="!mobile">
      <b-col sm="4">
        <b-form-select
          v-model="day"
          :options="daysOfTheWeekOptions"
        />
      </b-col>
      <b-col>
        <b-row>
          <b-col
            class="pr-0"
            sm="6"
          >
            <b-form-input
              v-model="from"
              type="time"
            />
          </b-col>
          <div class="m-auto">
            -
          </div>
          <b-col
            class="p-0"
            sm="5"
          >
            <b-form-input
              v-model="until"
              type="time"
            />
          </b-col>
        </b-row>
      </b-col>
    </template>
    <template v-else>
      <b-col class="p-1 pt-0">
        <b-row>
          <b-col cols="3">
            Day:
          </b-col>
          <b-col cols="9">
            <b-form-select
              v-model="day"
              :options="daysOfTheWeekOptions"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="3">
            From:
          </b-col>
          <b-col cols="9">
            <b-form-input
              v-model="from"
              type="time"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="3">
            Until:
          </b-col>
          <b-col cols="9">
            <b-form-input
              v-model="until"
              type="time"
            />
          </b-col>
        </b-row>
      </b-col>
    </template>
  </b-form-row>
</template>

<script>
export default {
  name: 'NoDates',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const sunday = new Date()
    sunday.setDate(sunday.getDate() - sunday.getDay())
    return {
      daysOfTheWeekOptions: [
        { value: null, text: 'Not selected' },
        { value: 1, text: 'Monday' },
        { value: 2, text: 'Tuesday' },
        { value: 3, text: 'Wednesday' },
        { value: 4, text: 'Thursday' },
        { value: 6, text: 'Friday' },
        { value: 6, text: 'Saturday' },
        { value: 0, text: 'Sunday' },
      ],
      day: null,
      from: null,
      until: null,
      sunday,
    }
  },
  computed: {
    mobile() {
      return this.$store.getters['app/currentBreakPoint'] === 'xs'
    },
  },
  watch: {
    value() {
      if (this.value === null) {
        return
      }
      this.from = this.value.from.toTimeString().slice(0, 5)
      this.until = this.value.until.toTimeString().slice(0, 5)
      this.day = this.value.from.getDay()
    },
    day() {
      this.setDate(this.value.from, this.day)
      this.setDate(this.value.until, this.day)
    },
    from() {
      this.setHours(this.value.from, this.from)
    },
    until() {
      this.setHours(this.value.until, this.until)
    },
  },
  created() {
    // if time diff is smaller then one minuit the from, until and day should not be set
    if (Math.abs(this.value.from - this.value.until) > 60000) {
      this.from = this.value.from.toTimeString().slice(0, 5)
      this.until = this.value.until.toTimeString().slice(0, 5)
      this.day = this.value.from.getDay()
    }
  },
  methods: {
    setHours(dateTime, timeString) {
      const hours = timeString.split(':')
      dateTime.setHours(hours[0], hours[1])
    },
    setDate(dateTime, day) {
      dateTime.setDate(this.sunday.getDate() + day)
    },
    save() {
      if (this.value.id === null && this.day === null) {
        return
      }
      if (this.value.id !== null && this.day === null) {
        this.value.deleteThis().then(() => {
          this.value.from = null
          this.value.until = null
        })
        return
      }
      this.value.save()
    },
  },
}
</script>
