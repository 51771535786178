<template>
  <b-modal
    id="modal-center"
    v-model="visible"
    centered
    :title="formattedDate(date.start, 'MMMM D, YYYY - HH:mm')"
    hide-footer
  >
    <b-row>
      <b-col>
        Time:
      </b-col>
      <b-col>
        {{ formattedDate(date.start, 'HH:mm') }}
      </b-col>
    </b-row>
    <template v-if="date.extendedProps">
      <b-row>
        <b-col>
          Type:
        </b-col>
        <b-col>
          {{ date.extendedProps.dateType }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          Dater 1:
        </b-col>
        <b-col>
          {{ date.extendedProps.person1 }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          Dater 2:
        </b-col>
        <b-col>
          {{ date.extendedProps.person2 }}
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from 'bootstrap-vue'
import moment from 'moment'

export default {
  name: 'DateDetails',
  components: {
    BModal,
    BRow,
    BCol,
  },
  props: {
    show: Boolean,
    date: {
      default: new Date(),
      type: Object,
    },
  },
  data() {
    return {
      visible: this.show,
    }
  },
  watch: {
    show() {
      this.visible = this.show
    },
    visible() {
      this.$emit('update:show', this.visible)
    },
  },
  methods: {
    formattedDate(date, format) {
      return moment(date).format(format)
    },
  },
}
</script>

<style scoped>

</style>
