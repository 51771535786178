<template>
  <b-modal
    id="modal-center"
    v-model="visible"
    centered
    :title="formattedDate(date.start, 'MMMM D, YYYY - HH:mm')"
    hide-footer
  >
    <div class="pb-2">
      To decline this reservation, please call <br><br>
      <span class="h3">+31 10 3220150</span>
    </div>
    <!--    <div>-->
    <!--      <b-form-textarea-->
    <!--        placeholder="Reason (optional)"-->
    <!--        rows="3"-->
    <!--      />-->
    <!--      <b-button-->
    <!--        variant="primary"-->
    <!--        class="d-block ml-auto mt-1"-->
    <!--      >-->
    <!--        Decline-->
    <!--      </b-button>-->
    <!--    </div>-->
  </b-modal>
</template>

<script>
import {
  BModal,
  // BFormTextarea,
  // BButton,
} from 'bootstrap-vue'
import moment from 'moment'

export default {
  name: 'DateDecline',
  components: {
    BModal,
    // BFormTextarea,
    // BButton,
  },
  props: {
    show: Boolean,
    date: {},
  },
  data() {
    return {
      visible: this.show,
    }
  },
  watch: {
    show() {
      this.visible = this.show
    },
    visible() {
      this.$emit('update:show', this.visible)
    },
  },
  methods: {
    formattedDate(date, format) {
      return moment.utc(date).format(format)
    },
  },
}
</script>

<style scoped>

</style>
