<template>
  <b-input-group>
    <b-input-group-prepend>
      <b-btn
        variant="outline-secondary"
        :disabled="number<=min"
        @click="numberMinusOne"
      >
        -
      </b-btn>
    </b-input-group-prepend>
    <b-form-input
      v-model.number="number"
      type="number"
      min="0"
    />
    <b-input-group-append>
      <b-btn
        variant="outline-secondary"
        @click="numberPlusOne"
      >
        +
      </b-btn>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
export default {
  name: 'PlusMinusNumberInput',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      number: this.value,
    }
  },
  watch: {
    number() {
      this.$emit('input', this.number)
    },
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.number = newVal
      }
    },
  },
  methods: {
    numberMinusOne() {
      if (this.number < 1) {
        return
      }
      this.number -= 1
    },
    numberPlusOne() {
      this.number += 1
    },
  },
}
</script>
