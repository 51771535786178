<template>
  <b-modal
    id="modal-center"
    v-model="visible"
    centered
    size="lg"
    title="Reservation preferences"
    :hide-header="!mobile"
    ok-title="Save"
    @ok="save"
  >
    <h1 v-if="!mobile">
      Reservation preferences
    </h1>
    <b-row>
      <b-col
        class="p-0"
        sm="5"
      >
        <b-card
          title="Default daily maximum"
          class="shadow-none"
        >
          <div class="pb-2">
            We’ll use it as guideline and try to stay within the limit as much as possible. <br>
            <b-link v-b-modal.preferences-dialog>
              Why is this?
            </b-link>
          </div>
          <b-form>
            <b-form-row
              v-for="(day, i) in daysOfTheWeek"
              :key="i"
              class="mb-1"
            >
              <b-col sm="5">
                {{ day }}:
              </b-col>
              <b-col
                v-if="defaultDailyMaximum.get((i+1)%7)!=null"
                sm="7"
              >
                <plus-minus-number-input v-model="defaultDailyMaximum.get((i+1)%7).maximum" />
              </b-col>
            </b-form-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col
        class="p-0 hidden"
        sm="7"
      >
        <b-card
          title="No dates"
          class="m-0 shadow-none"
        >
          Busy at certain times? Tell us when you prefer not to receive any dates. You can set two time slots in the week.
          <b-form class="mt-1">
            <NoDates
              ref="noDates1"
              v-model="noDates1"
            />
            <NoDates
              ref="noDates2"
              v-model="noDates2"
              class="pt-1"
            />
          </b-form>
        </b-card>
        <b-card
          title="Settings"
          class="shadow-none"
        >
          <b-form>
            <b-row>
              <b-col class="p-1">
                Notification reservation
              </b-col>
              <b-col>
                <b-form-select
                  v-model="location.reservationMethod"
                  :options="reservationMethods"
                />
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="preferences-dialog"
      header-class="h2 border-bottom-0"
      size="sm"
      title="Why we use your max as a guideline"
      ok-only
    >
      <div class="my-1">
        Many of our partners have temporarily closed their doors or drastically limited the dates that they want due to the current measures. Because we know our users love to have a date in a cafe, we do our best to meet that need. This means that we might go over your provided limit some times while we try to partner up with more locations in the process.
        <br>
        <br>
        This is temporary and we hope that you understand!
      </div>
    </b-modal>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import NoDates from '@/components/reservation-preferences/NoDates.vue'
import PlusMinusNumberInput from '../Util/PlusMinusNumberInput.vue'

export default {
  name: 'DatePreferences',
  components: { PlusMinusNumberInput, NoDates },
  props: {
    show: Boolean,
  },
  data() {
    const defaultDailyMaximum = new Map()
    const reservationMethods = [
      { text: 'Unknown', value: 'unknown' },
      { text: 'Call', value: 'call' },
      { text: 'Sms', value: 'sms' },
      { text: 'Email', value: 'email' },
      { text: 'Your own website', value: 'internal' },
      { text: 'Formitable', value: 'formitable' },
      // { text: 'Custom', value: 'custom' },
    ]
    return {
      number: 0,
      visible: this.show,
      daysOfTheWeek: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ],
      defaultDailyMaximum,
      reservationMethods,
    }
  },
  computed: {
    ...mapGetters({
      location: 'authentication/location',
    }),
    mobile() {
      return this.$store.getters['app/currentBreakPoint'] === 'xs'
    },
  },
  watch: {
    location: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.id !== oldVal.id) {
          this.fetchData()
        }
      },
    },
    visible() {
      this.$emit('update:show', this.visible)
    },
    show() {
      this.visible = this.show
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    save() {
      this.defaultDailyMaximum.forEach(val => {
        val.save()
      })
      this.$refs.noDates1.save()
      this.$refs.noDates2.save()
      this.location.save()
    },
    fetchData() {
      if (!this.location.id) return
      this.noDates1.locationId = this.location.id
      this.noDates2.locationId = this.location.id
      const date = new Date()
      date.setHours(0, 0, 0, 0, 0)
      const from = date.toISOString()
      date.setDate(date.getDate() + 7)
      date.setHours(24, 0, 0, 0)
      const until = date.toISOString()
      this.location.listAvailability(from, until).then(availabilities => {
        if (availabilities.length > 0) {
          this.mapAvailabilities(availabilities)
        }
        this.initAvailabilities()
        // to notify listeners about map update (Vue does not support reactivity in depth for map).
        this.defaultDailyMaximum = new Map(this.defaultDailyMaximum)
      })
    },
    mapAvailabilities(availabilities) {
      let i = 0
      availabilities.forEach(availability => {
        if (availability.type === 'default-daily-maximum') {
          this.defaultDailyMaximum.set(availability.from.getDay(), availability)
        } else if (availability.type === 'busy-hours') {
          if (i === 0) this.noDates1 = availability
          else if (i === 1 && this.noDates1.eventId !== availability.eventId) this.noDates2 = availability
          i += 1
        }
      })
    },
    initAvailabilities() {
      const date = new Date()
      const sunday = new Date(date.setDate(date.getDate() - date.getDay()))
      const existingAvailabilityDays = Array.from(this.defaultDailyMaximum.keys())
      for (let i = 0; i < 7; i += 1) {
        if (existingAvailabilityDays.indexOf(i) === -1) {
          const temp = new Date()
          temp.setHours(0, 0, 0, 0)
          temp.setDate(sunday.getDate() + i)
        }
      }
    },
  },
}
</script>

<style scoped>
  .card-body {
    padding: 1rem;
  }
</style>
