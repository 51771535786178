<template>
  <Calendar />
</template>

<script>
import Calendar from '@/components/calendar/Calendar.vue'

export default {
  components: {
    Calendar,
  },
}
</script>

<style>

</style>
