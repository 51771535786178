<template>
  <div class="card">
    <div class="d-flex">
      <template v-if="!mobile">
        <div class="flex-grow-1 app-calendar overflow-hidden border">
          <!-- Calendar -->
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
          </div>
        </div>

        <!-- Sidebar -->
        <div class="p-2 hide-below">
          <b-calendar
            v-model="selectedDate"
            hide-header
            locale="en"
            label-help=""
            @selected="goToDate"
          />
          <div class="py-2">
            <h1>{{ formattedDate(selectedDate, 'MMMM D, YYYY') }}</h1>
            <div class="row">
              <div class="col">
                Reservations
              </div>
              <div class="col">
                {{ amountOfReservations }}
              </div>
            </div>
          </div>
          <div class="row mt-5" />
          <div class="row">
            <div class="col  text-center hidden">
              <b-button
                variant="primary"
                @click="openPrefrences"
              >
                Preferences
              </b-button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <!-- Sidebar -->
        <div class="row">
          <div class="row flex-grow-1 app-calendar overflow-hidden border">
            <!-- Calendar -->
            <div class="card shadow-none border-0 mb-0 rounded-0">
              <div class="card-body pb-0">
                <div class="row">
                  <div class="col  text-center hidden">
                    <b-button
                      variant="primary"
                      @click="openPrefrences"
                    >
                      Preferences
                    </b-button>
                  </div>
                </div>
                <br>
                <full-calendar
                  ref="refCalendar"
                  :options="calendarOptions"
                  class="full-calendar"
                />
                <max-per-day
                  :date="selectedDate"
                  class="row mt-5"
                />
                <br>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Modals -->
      <DateDetails
        :show.sync="showDateDetails"
        :date="clickedReservation"
      />

      <DateDecline
        :show.sync="showDateDecline"
        :date="clickedReservation"
      />
      <DatePreferences
        :show.sync="showDatePreferences"
      />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import DateDetails from '@/components/modals/DateDetails.vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import moment from 'moment'
import DateDecline from '@/components/modals/DateDecline.vue'
import DatePreferences from '@/components/modals/DatePreferences.vue'
import { BCalendar } from 'bootstrap-vue'
import { requests } from '@/_helpers/requests'
import { mapGetters } from 'vuex'

export default {
  components: {
    DateDecline,
    DateDetails,
    FullCalendar,
    BCalendar,
    DatePreferences,
  },
  data() {
    return {
      clickedReservation: {},
      selectedDate: new Date(),
      amountOfReservations: '',
      showDateDetails: false,
      showDateDecline: false,
      showDatePreferences: false,
      hideEventsCount: 0,
      filterFrom: null,
      hideReservationsEvent: null,
      reservations: [],
      // CALENDAR SETTINGS
      calendarOptions: {
        // CALENDAR LAYOUT
        allDaySlot: false,
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'timeGridWeek',
        headerToolbar: {
          start: 'prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        contentHeight: 'auto',
        timeZone: 'local',
        slotMinTime: '09:00:00',
        slotDuration: '00:60:00',
        slotEventOverlap: false,
        slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false,
        },
        // EVENTS
        events: [],
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false,
        },
        eventDisplay: 'block',
        eventBackgroundColor: '#2140A1',
        eventBorderColor: '#2140A1',
        eventClassNames: ['font-weight-normal', 'p-0'],
        eventContent: this.createCustomContent,
        eventClick: this.handleReservationClick,
        // DATE CHANGE
        datesSet: this.handleNextPrevDay,
        dateClick: this.handleDateClick,
        navLinks: true,
        navLinkDayClick: this.handleLinkDayClick,
      },
    }
  },
  computed: {
    ...mapGetters({
      location: 'authentication/location',
    }),
    mobile() {
      return this.$store.getters['app/currentBreakPoint'] === 'xs'
    },
    hideEventsExplanation() {
      return !this.mobile ? 'You can’t see these reservations yet because we manually '
        + 'shuffle things around until 14:00 the day before the date in order to best meet your preferences.'
        : 'Not yet available'
    },
  },
  watch: {
    selectedDate: {
      immediate: true,
      handler() {
        this.updateAmountOfReservations()
      },
    },
    location(val) {
      // get reservations when location has id
      if (val.id) this.getReservations()
    },
  },
  mounted() {
    const filterFrom = new Date()
    if (filterFrom.getHours() >= 14) filterFrom.setDate(filterFrom.getDate() + 2)
    else filterFrom.setDate(filterFrom.getDate() + 1) // Filter dates from two days onwards.
    filterFrom.setHours(2, 0, 0, 0)
    const end = new Date()
    end.setDate(end.getDate() + 31)

    const hideReservationsEvent = {
      id: 1,
      title: this.hideEventsExplanation,
      start: filterFrom.toISOString().substring(0, 10),
      end: end.toISOString().substring(0, 10),
      textColor: 'white',
      overlap: false,
      display: 'background',
      backgroundColor: '#ff9f89',
      extendedProps: {
        overlay: true,
      },
    }
    this.hideReservationsEvent = hideReservationsEvent
    this.filterFrom = filterFrom
    this.getReservations()
    setInterval(this.getReservations, 300000) // Refetch every 5 mins
  },
  methods: {
    getReservations() {
      if (this.location.id) {
        requests.post(`locations/${this.location.id}/matches`)
          .then(response => this.setReservations(response.data))
      }
    },
    goToDate(date) {
      this.$refs.refCalendar.getApi().gotoDate(new Date(date))
    },
    handleNextPrevDay(dateInfo) {
      this.hideEventsCount = 0
      if (dateInfo.view.type === 'timeGridDay') this.selectedDate = dateInfo.startStr
    },
    handleReservationClick(data) {
      this.clickedReservation = data.event
      if (data.jsEvent.target.className === 'custom-close') this.showDateDecline = true
      else this.showDateDetails = true
    },
    handleDateClick(dateInfo) {
      this.selectedDate = dateInfo.dateStr
    },
    handleLinkDayClick(date) {
      this.selectedDate = date
    },
    formattedDate(date, format) {
      return moment(date).format(format)
    },
    updateAmountOfReservations() {
      const reservationsOnDate = this.reservations.filter(reservation => moment(reservation.start).isSame(this.selectedDate, 'day'))
      this.amountOfReservations = reservationsOnDate.length
    },
    createCustomContent(arg) {
      if (arg.event.extendedProps.overlay) {
        const customContent = document.createElement('div')
        customContent.classList.add('custom-title')
        if (this.hideEventsCount === 0) customContent.innerHTML = `<div id="custom-title-${this.hideEventsCount}" class="custom-body p-1"> ${arg.event.title} </div>`
        else customContent.innerHTML = ''
        this.hideEventsCount += 1
        return { domNodes: [customContent] }
      }
      const customContent = document.createElement('div')
      customContent.classList.add('d-flex', 'justify-content-between', 'h-100')
      // eslint-disable-next-line no-useless-concat
      customContent.innerHTML = '<div class="overflow-hidden">' + `${moment(arg.event.start).format('HH:mm')}<br>${arg.event.extendedProps.person1} & ${arg.event.extendedProps.person2}` + '</div><a class="custom-close">×</a>'
      return { domNodes: [customContent] }
    },
    setReservations(rawReservations) {
      this.reservations = []
      this.reservations.push(this.hideReservationsEvent)
      const filteredReservations = rawReservations.filter(reservation => {
        const date = new Date(reservation.datetime)
        return date.getTime() < this.filterFrom.getTime()
      })
      filteredReservations.forEach((rawReservation, index) => {
        const reservation = {
          id: Number,
          title: String,
          start: Date,
          end: Date,
          textColor: 'white',
          extendedProps: {
            person1: String,
            person2: String,
            dateType: String,
          },
        }
        // Required for FullCalendar
        reservation.id = index
        reservation.title = rawReservation.deal.name
        reservation.start = new Date(rawReservation.datetime)
        reservation.end = moment(new Date(rawReservation.datetime).setHours(new Date(rawReservation.datetime).getHours() + 2)).format()
        // Optional for Full Calendar
        reservation.extendedProps.person1 = rawReservation.matchedUsers[0]?.user?.profile.firstName || 'Deleted account'
        reservation.extendedProps.person2 = rawReservation.matchedUsers[1]?.user?.profile.firstName || 'Deleted account'
        reservation.extendedProps.dateType = rawReservation.deal.name
        this.reservations.push(reservation)
      })
      this.calendarOptions.events = this.reservations
      this.updateAmountOfReservations()
    },
    openPrefrences() {
      this.showDatePreferences = true
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.custom-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  line-height: 20px;
  color: white;

  .fc-list-event & {
    position: relative;
    line-height: normal;
  }
}

.custom-title {
  color: black;
  font-weight: bold;
  overflow: hidden;
  height: 100%;
  z-index: 1000;
}
.custom-title:hover {
  overflow: visible;
  z-index: 1000;
}

.custom-body:hover {
  background: #ff9f89;
  overflow: visible;
  opacity: 1;
  z-index: 1000;
 }
</style>
